// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyChmvFVUywgfGzT5q_KBA5WsdqE6e_m2H0",
//   authDomain: "react-chat-6c0cd.firebaseapp.com",
//   projectId: "react-chat-6c0cd",
//   storageBucket: "react-chat-6c0cd.appspot.com",
//   messagingSenderId: "823471845752",
//   appId: "1:823471845752:web:b532329b7c43fa1eb2d88d",
//   measurementId: "G-50MWMBYTSS"
// };

/// live config
const firebaseConfig = {
  apiKey: "AIzaSyAOWI3eWzcz-Dzf3r-la3KD_03mGXnAQI4",
  authDomain: "furutcamps-host.firebaseapp.com",
  projectId: "furutcamps-host",
  storageBucket: "furutcamps-host.firebasestorage.app",
  messagingSenderId: "689972689637",
  appId: "1:689972689637:web:440414771eecccedef720c",
  measurementId: "G-NSJVQV81VK"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
// export const auth = getAuth(app);
// export const db = getFirestore(app);
// Initialize Firebase
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, provider, signInWithPopup, facebookProvider };