import 'bootstrap/dist/css/bootstrap.min.css';

import footerLogo from '../../../src/assets/img/furutcamp-logo-white.svg'
import footerLogoSm from '../../../src/assets/images/Footer-Logo-sm.png'
import monogram from '../../../src/assets/img/FURUT-MONOGRAM.svg'
import furutaction1 from '../../../src/assets/img/furutaction-logo-white.svg'
import furutaction2 from '../../../src/assets/img/furutstore-logo-white.svg'
import badgeName from '../../../src/assets/img/badge-name.svg'
import Features from '../Comman/Features';

const FooterComponent = () => {
   
    return (
        <>
         <Features />
            <footer className='footer'>
                <div className='container p-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="d-flex flex-column justify-content-lg-evenly flex-lg-row">
                                <div className="d-flex flex-lg-grow-1"><img src={monogram} className='img-fluid' height="32px" alt='' />
                                    <div className="vr ms-3 me-3"></div>

                                    <img src={footerLogo} height="32px" />
                                </div>
                                <div className="d-flex align-baseline mt-4 mt-lg-0 ms-5">
                                    <a href="#"><img src={furutaction1} height="32px" /></a>
                                    <div className="vr ms-3 me-3"></div>
                                    <a href="#"><img src={furutaction2} height="28px" className="mt-2" /></a>
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className='footer_wrapper row mt-5 mb-5'>
                                <div className='footer_links col-lg-3 col-md-3 col-sm-6'>
                                    <h5 className='mt-5'>About</h5>

                                    <a href='https://www.furutcamps.com/'>About furut camps</a>
                                    <a href='https://furut.net/furut-story/'>How furut camps works</a>
                                    <a href='https://www.furutcamps.com/contact/'>Contact</a>
                                </div>

                                <div className='footer_links col-lg-3 col-md-3 col-sm-6'>
                                    <h5 className='mt-5'>Join</h5>

                                    <a href='https://www.furutcamps.com/start-hosting-with-furutcamps/'>Become a host</a>
                                    <a href='https://www.furutcamps.com/furut-explorers-explore-the-great-outdoors/'>furut-explorers</a>
                                    <a href='https://www.facebook.com/groups/furutcampers/'>Join our facebook group</a>
                                    <a href='https://www.instagram.com/furutcamps'>Follow us on instagram</a>
                                    <a href='https://www.youtube.com/@furutcamp'>subscribe to our youtube channel</a>
                                </div>

                                <div className='footer_links col-lg-3 col-md-3 col-sm-6'>
                                    <h5 className='mt-5'>Help & Policies</h5>

                                    <a href='https://www.furutcamps.com/host-policy/'>Host policy</a>
                                    <a href='https://www.furutcamps.com/guest-policy/'>Guest policy</a>
                                    <a href='https://www.furutcamps.com/help/'>Help & Support</a>
                                </div>

                                <div className='footer_links col-lg-3 col-md-3 col-sm-6'>
                                    <h5 className='mt-5'>Resources</h5>

                                    <a href='https://www.furutcamps.com/category/camp-logs/'>Camp Logs</a>
                                    <a href='https://www.furutcamps.com/category/tips-tricks/'>Tips n Tricks</a>
                                    <a href='https://www.furutcamps.com/category/news-media/'>News & media</a>
                                    <a href='https://www.furutcamps.com/category/guides/'>Guides</a>
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <div class="d-flex justify-content-lg-around flex-column flex-lg-row">
                                <p class="flex-grow-1 align-middle flex-row m-auto mb-5 mb-lg-0 text-white">proudly associated with</p>
                                <div class="d-flex justify-content-around flex-grow-1">
                                    <img src={badgeName} />
                                    <img src={badgeName} />
                                    <img src={badgeName} />
                                    <img src={badgeName} />
                                    <img src={badgeName} />
                                    <img src={badgeName} />
                                </div>
                            </div>
                        </div>

                        <hr className="mt-5 mb-5" />

                        <div className='col-12'>
                            <div class="d-flex justify-content-lg-around flex-column flex-lg-row">
                                <small class="flex-grow-1 mb-5 text-white"> © copyright2023@furutcamps pvt ltd. | <a href="https://www.furutcamps.com/terms" class="text-light text-decoration-underline">terms of use</a> <span className='text-white'>|</span> <a href="https://www.furutcamps.com/privacy/" class="text-light text-decoration-underline">privacy</a> </small>
                                <div>
                                    <a href="https://www.facebook.com/furutcamps/" class="text-light text-decoration-underline">facebook</a> <span className='text-white'>|</span> <a href="https://www.instagram.com/furutcamps/" class="text-light text-decoration-underline">instagram</a> <span className='text-white'>|</span> <a href="https://www.youtube.com/@furutcamp" class="text-light text-decoration-underline">youtube</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    )
}


export default FooterComponent

// <div className='footer_logo_wrapper d-flex align-items-center'>
//     <img src={footerLogoSm} className='img-fluid' alt='' />
//     <img src={footerLogoSm} className='img-fluid' alt='' />
//     <img src={footerLogoSm} className='img-fluid' alt='' />
// </div>

// <div className='copyright_sec d-flex align-items-center'>
//     <p>Copyright 2021@camps.furut.net</p>
//     <p> <a href='#'>Trems of use</a> | <a href='#'>Privacy policy</a> </p>

//     <p> <a href='#'>Faecbook</a> | <a href='#'>Instagram</a> | <a href='#'>Youtube</a> </p>
// </div>